import React from 'react';
import { GaleriaImagenes } from '../../components/GaleriaImagenes';
import { VisitarBanner } from '../../components/VisitarBanner';

export const AboutUs = () => {
  return (
    <div>
      <VisitarBanner />
      <div className='aboutUs__divDescripcionFlex'>
        <div className='aboutUs__divDescripcion nosotros__text'>
          <p>
            En Casa Carina llevamos más de 40 años acompañando a quienes
            construyen sueños en Argentina. Somos referentes en la venta de
            materiales para la construcción y artículos de ferretería,
            ofreciendo productos de calidad que respaldan los proyectos de
            profesionales y particulares.
          </p>
          <p>
            Nos distingue nuestra experiencia, el trato cercano y el compromiso
            con cada cliente. ¡Acercate y encontrá todo lo que buscás para tu
            obra al mejor precio!
          </p>
        </div>
      </div>
      <div className='aboutUs__GaleriaImagenesFlex'>
        <div className='aboutUs__GaleriaImagenes'>
          <GaleriaImagenes />
        </div>
      </div>
    </div>
  );
};
